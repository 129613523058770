
import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import './Banner.scss';
import Tabbing from "../Tabbing/Tabbing";
import BannerSelect from "../BannerSelect/BannerSelect";
//import { AnimationOnScroll } from 'react-animation-on-scroll';
import Helmet from 'react-helmet'

import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
import ReviewBadge from "../ReviewBadge/ReviewBadge";

const Banner = (props) => {
    const [scroll, setScroll] = React.useState(false);
    const [screenSize, setScreenSize] = useState(0);

    React.useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 60)
        })
    }, [])

    const tabbingData = {
        'Buy':
        {
            location: ["Location", "Rent", "Off Plan", "Holiday Lets", "list a property"],
            propertyType: ["Property Type", "Rent", "Off Plan", "Holiday Lets", "list a property"],
            maxPrice: ["Maximum Price", "Rent", "Off Plan", "Holiday Lets", "list a property"],
        },
        'Rent':
        {
            location: ["Location 2", "Rent", "Off Plan", "Holiday Lets", "list a property"],
            propertyType: ["Property Type", "Rent", "Off Plan", "Holiday Lets", "list a property"],
            maxPrice: ["Maximum Price", "Rent", "Off Plan", "Holiday Lets", "list a property"],
        },
        'Off Plan':
        {
            location: ["Location 3", "Rent", "Off Plan", "Holiday Lets", "list a property"],
            propertyType: ["Property Type", "Rent", "Off Plan", "Holiday Lets", "list a property"],
            maxPrice: ["Maximum Price", "Rent", "Off Plan", "Holiday Lets", "list a property"],
        },
        'Holiday Lets':
        {
            location: ["Location 4", "Rent", "Off Plan", "Holiday Lets", "list a property"],
            propertyType: ["Property Type", "Rent", "Off Plan", "Holiday Lets", "list a property"],
            maxPrice: ["Maximum Price", "Rent", "Off Plan", "Holiday Lets", "list a property"],
        },
        'list a property':
        {
            location: ["Location 5", "Rent", "Off Plan", "Holiday Lets", "list a property"],
            propertyType: ["Property Type", "Rent", "Off Plan", "Holiday Lets", "list a property"],
            maxPrice: ["Maximum Price", "Rent", "Off Plan", "Holiday Lets", "list a property"],
        },
    }
    let processedImages = JSON.stringify({});
    if (props.imagetransforms?.Banner_image_Transforms) {
      processedImages = props.imagetransforms.Banner_image_Transforms;
    }
    
    useEffect(() => {
        deviceWidth()
        window.addEventListener("resize", () => {
            deviceWidth()
        });
    })
  
    const deviceWidth = () => {
        if (window) {
            setScreenSize(window.innerWidth)
        }
    }

    let getImg= props.banner_image?.url? props.banner_image?.url:""
    let findImage = getImg?.substring(getImg?.indexOf("i."));

    var myObject = JSON.parse(props?.imagetransforms?.Banner_image_opt_Transforms);
    let imgUrl = ""
    if(screenSize<=767) {
        imgUrl = getImg //myObject[findImage]["webp"]["374ct220"]?myObject[findImage]["webp"]["374ct220"]:getImg        
    }else {            
        imgUrl = getImg //myObject[findImage]["webp"]["600ct616"]?myObject[findImage]["webp"]["600ct616"]:getImg
    }

    return (
        <>
         <Helmet>
              <link rel="preload" href={imgUrl} as="image" type="image/webp"  />
         </Helmet>
            <section className={scroll ? "banner banner--fixed" : "banner"}>
                <Container>
                    <Row>
                        <Col>
                            <div className="banner-wrapper">
                                <div className="ltl-wrapper">
                                    {props.banner_image ?
                                        <div className="banner-img">
                                            <ImageTransform 
                                            imagesources={props.banner_image?.url} 
                                            renderer="srcSet" 
                                            imagename="pages.Banner_image_opt.banner" 
                                            attr={{ alt: 'Home Banner Image - Arabian Estates', class: '' }} 
                                            imagetransformresult={processedImages} 
                                            id={props.id} format="source" 
                                            imagetype="nolazy" />

                                            {/* <img src={props.banner_image.url} alt={props.banner_image.alternativeText} /> */}
                                        </div>
                                    : '' }
                                </div>
                                <div className="rtl-wrapper">
                                        {parse(props.banner_content)}
                                        {props.show_search ?
                                        <React.Fragment>
                                        <div className="d-none d-md-block">
                                            <Tabbing tabbingData={tabbingData} />
                                        </div>
                                        <div className="d-md-none">
                                            <BannerSelect tabbingData={tabbingData} />
                                        </div>
                                        </React.Fragment> : '' }
                                        {props.show_reviews ?
                                        <div className="google-review">
                                            <ReviewBadge />
                                        </div> : '' }
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}



export default Banner